/**
 * Wraps a function and returns a debounced version that can be called with the same parameters as the original. A good rule of thumb is to debounce by the approximate end-to-end latency of the function, although it doesn't guarantee non-concurrent operation. Commonly used to throttle "real-time" updates in response to sequential user input, such as typing.
 * @param func The function to debounce
 * @param delay Debounce buffer time in miliseconds
 * @returns A debounced wrapper of func
 */
// Infer return type from function itself
export const debounce = (func: (...args: unknown[]) => void, delay: number) => {
  let debounceTimer: NodeJS.Timeout;

  return (...args:Parameters<typeof func>) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};


/**
 * Creates a buffer that ensures only one process executes at a time, while storing at most one pending process that replaces any previous pending process.
 * Ideal for incremental background work based on user input where all unaddressed changes are included in each query. In such cases, it may be helpful for the queued callback to mark the relevant updates as "processing" (once it starts executing) to be excluded from following queued callbacks.
 * @returns A function to allow queueing arbitrary functions into the same one-process buffer.
 */
export function createSingleProcessBuffer() {
  let isExecuting = false;
  let bufferedProcess: (() => Promise<void>) | null = null;

  return async function bufferProcess(process: () => Promise<void>) {
    // Store this as the buffered process, replacing any existing one
    bufferedProcess = process;

    // If we're already executing, just return - this process will run
    // when the current one finishes
    if (isExecuting) {
      return;
    }

    // Keep executing processes until the buffer is empty
    isExecuting = true;
    try {
      while (bufferedProcess) {
        const currentProcess = bufferedProcess;
        bufferedProcess = null;
        await currentProcess();
      }
    } finally {
      isExecuting = false;
    }
  };
}