import React, { useState, useEffect } from 'react';
import { IonCol, IonRow, IonGrid, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonLabel, IonButton, IonSpinner, IonIcon } from '@ionic/react';
import { LoginGuard } from '../LoginGuard';
import InfoStub from '../InfoStub';
import MultiColumnContent, { TabContent } from './multi-column-content';
import FileUpload from '../../services/client-records/file-upload';
import { ClientRecord, ReportRecord, useClients } from '../../services/client-records/clients-provider';
import useFileMetadata, { FileMetadata } from '../../services/client-records/use-metadata';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore as db, storage } from "../../App"; // Your firebase configuration
import { trashBinOutline } from 'ionicons/icons';
import { ref as storageRef, deleteObject } from 'firebase/storage';
import PDFExtractor from '../../components/pdf-extractor';
import { Account, ExtractedMorningstarData, extractMorningstarData, extractTables, generateFormattedText, Holding } from './analysis';
import { Link, useParams } from 'react-router-dom';
import { useRecordings } from '../../services/RecordingsProvider';
import { SurveyAnswers } from '../../components/kyc-editor/question-types';
import { useInferences } from './use-inferences';
import { InsightCard } from './insight-card';

const ReportProcessor: React.FC = () => {
  const {clientId = "NO_CLIENT_ID", reportId = "NO_REPORT_ID"} = useParams<{clientId:string, reportId:string}>();

  const { myReports, addReport } = useClients();
  const clientReports = myReports.get(clientId) || [];
  const matchingReport = clientReports.find(report => report.id===reportId);

  if (!matchingReport) {
    return <LoginGuard>
    <InfoStub title={"Report not found..."} backRouterLink={`/clients/${clientId}`}>
      <p>Report not found. This message may show while page is loading.</p>
    </InfoStub>
  </LoginGuard>
  }

  const tabContents: TabContent[] = [
    { title: "Holdings PDFs", content: <PDFPreviewContent report={matchingReport}/> },
    { title: "Holdings Tables", content: <HoldingsTableContent report={matchingReport}/> },
    { title: "Aggregate Morningstar", content: <AggregateMorningstarContent report={matchingReport}/> },
    { title: "Quantitative Analysis", content: <QuantitativeAnalysisContent report={matchingReport}/> },
    { title: "Strategy Recommendation", content: <StrategyRecommendationContent report={matchingReport}/> },
  ];

  return (
    <LoginGuard>
      <InfoStub title={"Started: " + (matchingReport?.createdAt?.toLocaleString() || "Report in Progress")} backRouterLink={`/clients/${clientId}`}>
        <MultiColumnContent tabs={tabContents} />
        <hr/>
        {/* <h1>Household Member 2</h1>
        <p>The most recent reports from any other household members will be shown here.</p>
        <MultiColumnContent tabs={tabContents} /> */}
      </InfoStub>
    </LoginGuard>
  );
};

const PDFPreviewContent: React.FC<{ report: ReportRecord }> = ({ report }) => {
  const fileMetadata = useFileMetadata(report).filter(data => data.documentType === "holdings report");

  const deleteFile = async (filePath: string) => {
    const fileRef = storageRef(storage, filePath);

    try {
      // Delete the file from Firebase Storage
      await deleteObject(fileRef);
      console.log(`Successfully deleted file at path: ${filePath}`);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  return (
    <>
      <FileUpload clientId={report.clientId} reportId={report.id} documentType='holdings report' />
      {fileMetadata.map((file, index) => {
        return (
          <IonCard style={{ marginBottom: "1em" }} key={index}>
            <IonCardHeader>
              <IonCardTitle>{file.fileName}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <iframe src={file.fileUrl} width="100%" height="500px" title={file.fileName} />
              <IonButton color="danger" onClick={() => deleteFile(file.filePath)}>
                <IonIcon icon={trashBinOutline} />
                Delete
              </IonButton>
            </IonCardContent>
          </IonCard>
        );
      })}
    </>
  );
};


const HoldingsTableContent: React.FC<{report: ReportRecord}> = ({ report }) => {
  const fileMetadata = useFileMetadata(report).filter(data => data.documentType === 'holdings report');
  const fileURLs = fileMetadata.map(metadata => metadata.fileUrl);
  const {updateReport} = useClients();

  const [extractedData, setExtractedData] = useState<Map<string, string>>(new Map());
  const [formattedText, setFormattedText] = useState<Map<string, string>>(new Map());
  const [accountsData, setAccountsData] = useState<Map<string, Account[]>>(new Map());
  const [status, setStatus] = useState<Map<string, "scanning document"|"extracting account data"|"formatting tables"|"processing complete">>(new Map()); // TODO: Better management.

  // Initialize/re-initialize accountsData on report reload.
  useEffect(()=>{
    setAccountsData(new Map(Object.entries(report.accountsData || {})));
  }, [report])

  const handleExtraction = async (fileUrl: string, data: string) => {
    setExtractedData(prevState => {
      const newState = new Map(prevState);
      newState.set(fileUrl, data);
      return newState;
    });
    setStatus(prevState => {
      const newState = new Map(prevState);
      newState.set(fileUrl, "extracting account data");
      return newState;
    });
    // Extract the text from the parse data.
    const structuredText = await generateFormattedText(data);
    setFormattedText(prevState => {
      const newState = new Map(prevState);
      newState.set(fileUrl, structuredText);
      return newState;
    });
    setStatus(prevState => {
      const newState = new Map(prevState);
      newState.set(fileUrl, "formatting tables");
      return newState;
    });
    // Extract the accounts tables from the parse data.
    const accountsTables = await extractTables(structuredText);
    setAccountsData(prevState => {
      const newState = new Map(prevState);
      newState.set(fileUrl, accountsTables);
      return newState;
    });
    // Update the File Metadata (cache the extracted tables)
    await updateReport(report.id, {
      accountsData: {...Object.fromEntries(accountsData), [fileUrl]:accountsTables}
    });
    setStatus(prevState => {
      const newState = new Map(prevState);
      newState.set(fileUrl, "processing complete");
      return newState;
    });
  };

  // const formatExtractedData = (data: Map<string, string>): string => {
  //   let result = '';
  //   data.forEach((innerData, fileUrl) => {
  //     result += `File: ${fileUrl}\n`;
  //     result += innerData;
  //     result += '\n';
  //   });
  //   return result;
  // };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('Text copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
      alert('Failed to copy text');
    }
  };

  const holdingsToTSV = (holdings: Holding[]): string => {
    // Define the headers
    const headers = ['symbol', 'asset name', 'value'];
  
    // Map the holdings to TSV rows
    const rows = holdings.map(holding => [
      holding.symbol ?? '',
      holding.longName ?? '',
      holding.value.toString()
    ]);
  
    // Join the headers and rows with tab and newline separators
    const tsvString = [
      headers.join('\t'),
      ...rows.map(row => row.join('\t'))
    ].join('\n');
  
    return tsvString;
  };

  return (
    <div style={{maxWidth: "25em"}}>
      {fileURLs.map((fileUrl, index) => (
        fileUrl && <IonCard key={fileUrl} style={{maxWidth: "25em"}}>
          <IonCardHeader>
            <IonCardTitle>From: {fileMetadata[index].fileName}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            {(!extractedData.has(fileUrl) && !(accountsData.has(fileUrl))) && <PDFExtractor
              file={fileUrl}
              callback={(data) => handleExtraction(fileUrl, data)}
            />}
            {/* {extractedData.has(fileUrl) && <button onClick={()=>{copyToClipboard(extractedData.get(fileUrl) || "")}}>Copy to Clipboard</button>} */}
            {/* {formattedText.has(fileUrl) && <pre style={{ whiteSpace: "pre-wrap" }}>{formattedText.get(fileUrl)}</pre>} */}
            {status.has(fileUrl) && status.get(fileUrl)!=="processing complete" && <>
              <p>{status.get(fileUrl)}</p>
              <IonSpinner/>
            </>}
            {accountsData.has(fileUrl) && <>
              {accountsData.get(fileUrl)?.map((account, index)=>{
                return <div key={index} style={{marginTop:"1em"}}>
                  <h3>{account.accountName}</h3>
                  <p>Qualified Account?: {account.taxAdvantagedStatus}</p>
                  <table>
                    <thead>
                      <tr>
                        <th>symbol</th>
                        <th>full holding name (if available)</th>
                        <th>value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {account.holdings.map((holding, holdingIndex)=>{
                        return <tr key={holdingIndex}>
                          <td>{holding.symbol}</td>
                          <td>{holding.longName}</td>
                          <td>${holding.value}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                  <p>Total Value: {account.holdings.reduce((sum, holding)=>sum+holding.value, 0)}</p>
                  <button onClick={()=>{copyToClipboard(holdingsToTSV(account.holdings))}}>Copy to Clipboard</button>
                </div>;
              })}
            </>}
          </IonCardContent>
        </IonCard>
      ))}
      {/* <pre style={{ whiteSpace: "pre-wrap" }}>{formatExtractedData(extractedData)}</pre> */}
    </div>
  );
};


const AggregateMorningstarContent: React.FC<{ report: ReportRecord }> = ({ report }) => {
  const fileMetadata = useFileMetadata(report).filter(data => data.documentType === "analysis");

  const deleteFile = async (filePath: string) => {
    const fileRef = storageRef(storage, filePath);

    try {
      // Delete the file from Firebase Storage
      await deleteObject(fileRef);
      console.log(`Successfully deleted file at path: ${filePath}`);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  return (
    <>
      <FileUpload clientId={report.clientId} reportId={report.id} documentType='analysis' />
      <p>(Note: If multiple Morningstar reports are uploaded here, only the first file will be processed.)</p>
      {fileMetadata.map((file, index) => {
        return (
          <IonCard style={{ marginBottom: "1em" }} key={index}>
            <IonCardHeader>
              <IonCardTitle>{file.fileName}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <iframe src={file.fileUrl} width="100%" height="500px" title={file.fileName} />
              <IonButton color="danger" onClick={() => deleteFile(file.filePath)}>
                <IonIcon icon={trashBinOutline} />
                Delete
              </IonButton>
            </IonCardContent>
          </IonCard>
        );
      })}
    </>
  );
};

const QuantitativeAnalysisContent: React.FC<{report: ReportRecord}> = ({ report }) => {
  const fileMetadata = useFileMetadata(report).filter(data => data.documentType === 'analysis');
  const firstFile = fileMetadata.length > 0? fileMetadata[0] : null;
  const firstFileUrl = firstFile?.fileUrl;
  const {updateReport} = useClients();

  const [extractedData, setExtractedData] = useState<string>("");
  const [morningstarData, setMorningstarData] = useState<ExtractedMorningstarData | null>(null);
  // Initialize/re-initialize accountsData on report reload.
  useEffect(()=>{
    setMorningstarData((report.morningstarData || null));
  }, [report]);

  const {internationalRecommendation, bondDurationRecommendation, bondCreditRecommendation} = useInferences(report);

  const [status, setStatus] = useState<"scanning pdf"|"extracting insights"|"processing complete"|null>(null);

  // TODO: Remove copyToClipboard
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('Text copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
      alert('Failed to copy text');
    }
  };

  // TODO: Save computed morningstar data.
  const handleExtractedData = async (parseString:string)=>{
    setExtractedData(parseString);
    setStatus("extracting insights");
    const morningstarData = await extractMorningstarData(parseString);
    // Cache in report.
    await updateReport(report.id, {
      morningstarData: morningstarData,
    });
    setMorningstarData(morningstarData);
    setStatus("processing complete");
  }

  if (!firstFile || !firstFileUrl) {
    return <IonCard>
      <IonCardHeader>
        <IonCardTitle>Morningstar Analysis (Pending Upload)</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <p>Upload a Morningstar report in the column to the left in order to see quantitative insights based on the report.</p>
      </IonCardContent>
    </IonCard>
  }

  return (
    <IonCard style={{ marginBottom: "1em", maxWidth: "25em" }}>
      <IonCardHeader>
        <IonCardTitle>From: {firstFile.fileName}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {!extractedData && !morningstarData && <PDFExtractor file={firstFileUrl} callback={handleExtractedData} keyPhrase="x-ray"/>}
        {status && status!=="processing complete" && <>
          <p>{status}</p>
          <IonSpinner/>
        </>}
        {/* {extractedData && <>
          <button onClick={()=>{copyToClipboard(extractedData)}}>Copy to Clipboard</button>
          <p>{extractedData}</p>
        </>} */}
        {/* {morningstarData? <>
          {Object.keys(morningstarData).map(key=><p key={key}>{key}: {JSON.stringify(morningstarData[key as keyof typeof morningstarData])}</p>)}
        </>: <></>}
        {morningstarData && <>
          <button onClick={()=>{copyToClipboard(JSON.stringify(morningstarData))}}>Copy to Clipboard</button>
        </>} */}
        {internationalRecommendation && <InsightCard recommendation={internationalRecommendation}/>}
        {bondDurationRecommendation && <InsightCard recommendation={bondDurationRecommendation}/>}
        {bondCreditRecommendation && <InsightCard recommendation={bondCreditRecommendation}/>}
      </IonCardContent>
    </IonCard>
  );
};

const StrategyRecommendationContent: React.FC<{report: ReportRecord}> = ({ report }) => {
  const [askedJunior, setAskedJunior] = useState(false);

  const {riskRecommendation} = useInferences(report);

  const { filesList } = useRecordings();
  const {myClients} = useClients();
  const matchingClient = myClients.find(client=>client.id === report.clientId) as undefined | (ClientRecord & Partial<SurveyAnswers>);

  const filteredRecordings = filesList.filter((file)=>JSON.stringify(file.customMetadata).toLowerCase().includes(matchingClient?.fullName.toLowerCase() || "[No client name found.]"))

  return (<>
    <IonCard style={{ marginBottom: "1em" }}>
      <IonCardHeader>
        <IonCardTitle>Suggested Talking Points</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {askedJunior? <>
          {riskRecommendation && <InsightCard recommendation={riskRecommendation}/>}
        </>:<IonButton onClick={()=>{setAskedJunior(true)}}>Ask Junior</IonButton>}
      </IonCardContent>
    </IonCard>
    {askedJunior && <>
      <h3>Insights from Recent Meetings</h3>
      {filteredRecordings.map((meeting, index) => (
        <IonCard key={index} style={{ marginBottom: "1em", maxHeight: "15em", overflow:"auto" }} routerLink="/smart-notes">
          <IonCardHeader>
            {meeting.customMetadata?.date && 
              <IonCardTitle>{new Date(Number(meeting.customMetadata?.date)).toLocaleString()}</IonCardTitle>
            }
          </IonCardHeader>
          <IonCardContent>
            {meeting.customMetadata?.summary.split("\n").map((paragraph, index) => <p key={index}>{paragraph}</p>)}
          </IonCardContent>
        </IonCard>
      ))}
    </>}
  </>);
};

export default ReportProcessor;
