import { useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../services/AuthProvider";
import { FullMetadata, getMetadata, listAll, ref, updateMetadata } from "firebase/storage";
import { proxyChatCall, storage } from "../App";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonDatetime, IonGrid, IonItem, IonLabel, IonRow, IonSearchbar, IonSpinner, IonText, IonToggle } from "@ionic/react";
import { useRecordings } from "../services/RecordingsProvider";
import { useQueue } from "../fullstack-utilities/task-queue-provider";
import ThreeBarChart from "./ThreeBarChart";
import { createSingleProcessBuffer } from "../fullstack-utilities/async-utilities";

export const questionCues = ["The reason for our meeting is...",
  "I'm recommending that...",
  "Is there anything that you wanted to talk about?",
  "We'll talk more about that later.",
  "What are you most worried about?",
  "Is there anything more I can do to add value to our relationship?",
  "Are there any assets not held with me that should inform our thinking?",
  "Next steps will be...",
  "I will follow up with...",
] as const;
type QuestionCue = typeof questionCues[number];

// Define a sleep function that returns a Promise
function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}; // Helper function used below to space out queries and avoid hitting usage rate limits.

const queueConcernSummaries = createSingleProcessBuffer();

export const RecordingsManager: React.FC = () => {
  // const { currentUser } = useAuth();
  // let uid = currentUser?.uid || "NO_USER_IDENTIFIED";
  // if (uid === "gQ6uSdk0sNSWwJQL1oPEJFK6Q5B2" || uid==="OHgrHEoqvQQToBQl49BTeyDqsNg1") { // For admin testing purposes.
  //   uid = "aWdbYqMOYSSyAWBbtjUUXf3dsJt2";
  // }
  // const [loadingListings, setLoadingListings] = useState(false);

  // const [filesList, setFilesList] = useState([] as FullMetadata[]);
  // filesList.sort((a, b)=>{return (Number.parseInt(b.customMetadata?.date || "") - Number.parseInt(a.customMetadata?.date || ""));}); // Sorts newest to oldest.

  // const fetchMetadata = async () => {
  //   setLoadingListings(true);
  //   // console.log(uid);
  //   const storageRef = ref(storage,  `user/${uid}/audio`);
  //   listAll(storageRef).then(function(result) {
  //     // console.log(result);
  //     const promises = result.items.map((ref)=>{
  //       return getMetadata(ref);
  //     });
  //     Promise.all(promises).then((metadata)=>{
  //       console.log(metadata);
  //       setFilesList(metadata);
  //     });
  //   }).catch(function(error) {
  //     // Handle any errors
  //   }).finally(()=>{
  //     setLoadingListings(false);
  //   });
  // };
  
  // useEffect(() => {
  //   fetchMetadata();
    
  //   // // TODO: DEBUGGING
  //   // currentUser?.getIdTokenResult().then((idTokenResult)=>{
  //   //   console.log("ID TOKEN:");
  //   //   console.log(JSON.stringify(idTokenResult));
  //   // })
  // }, []);

  const {filesList, loadingListings, refreshData, metadataComplete} = useRecordings();

  const columnStyles = {
    "height": "100vh",
    "overflowY": "auto"
  };

  const [searchText, setSearchText] = useState("");
  const [pickingStartDate, setPickingStartDate] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [pickingEndDate, setPickingEndDate] = useState(false);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const filteredListings = useMemo(()=>{
    return filesList.filter((file)=>{
      const dateString = file.customMetadata?.date;
      const date = dateString? (new Date(Number.parseInt(dateString))): new Date();
      const allText = JSON.stringify(file.customMetadata) + ` ${dateString? date.toLocaleString() : "No Timestamp"}`;
      return (!pickingStartDate || !startDate || date >= startDate) && (!pickingEndDate || !endDate || date <= endDate) && allText.includes(searchText); // TODO
    }); // TODO: Allow searching by multiple keywords, better filtering, semantic search.
  }, [filesList, searchText, startDate, pickingStartDate, endDate, pickingEndDate,]);

  const {addToQueue} = useQueue();

  /**
   * Checks if result is cached and if not, computes and saves in record.
   * @param file 
   * @param question 
   * @returns 
   */
  const getReportData = async (file: FullMetadata, question: string): Promise<string[] | undefined> => {
    const insights = JSON.parse(file.customMetadata?.insights || "{}") as {[key: string]: string[]};
    // console.log(`Insights: ${insights}`);
    if (insights[question] ) {
      // console.log(`Retrieving stored value: ${insights[question]}`);
      return insights[question];
    }

    // TODO: Deal with incomplete metadata more effectively.
    if (!file.customMetadata?.labeledUtterances) {
      return undefined;
    }

    // Collect evidence that answers the question in the affirmative?
    const functionCallName = "detect_statements";
    const properties: { [key: string]: any } = {};
    properties['direct_answer'] = {
      type: 'string',
      description: `A summary answer to the system-provided question: ${question}.`,
    };
    properties['relevant_statements'] = {
      type: 'array',
      items: {
        type: 'string',
      },
      description: `A list of statements from the user's text that directly support an affirmative answer. This should be empty if the verdict was not affirmative.`,
    };
    const response = await proxyChatCall({
      model: "gpt-4", // Results are better enough that this is worth it.
      // model: "gpt-4-turbo",
      temperature: 0,
      seed: 0,
      messages: [
        {
          role: "system",
          content: `You are a note-taking assistant for a financial advisor examining a recent client meeting, transcribed in the user's message. Please determine the answer to the following question by identifying supporting or relevant snippets from the transcript: ${question}`,
        },
        {
          role: "user",
          content: file.customMetadata?.labeledUtterances || "[no transcription available]",
        },
      ],
      tools: [
        {
          type: 'function',
          function: {
            name: functionCallName,
            description:
              "Identify whether the user's text contains certain kinds of information or statements.",
            parameters: {
              type: 'object',
              properties: properties,
              required: Object.keys(properties), // All properties are required.
            },
          },
        },
      ],
      tool_choice: { type: 'function', function: { name: functionCallName } },
    });
    const result = JSON.parse(
      (response.data as any)?.choices?.[0]?.message?.tool_calls?.[0]?.function.arguments
    )?.["relevant_statements"];
    // console.log(`Response to question: ${question}`);
    // console.log(result);

    // Cache result in storage.
    if (file.ref) {
      const newInsights = {
        ...insights,
      } as typeof insights;
      newInsights[question] = result;
      const newCustomMetadata = {
        ...file?.customMetadata,
        insights: JSON.stringify(newInsights),
      }
      // console.log(`Caching new data: ${newCustomMetadata}`);
      await updateMetadata(file.ref, {customMetadata: newCustomMetadata});
      //await refreshData();
      file.customMetadata = newCustomMetadata; // Update the synchronous version as well (without triggering a state reload).
    }

    // Return result.
    // TODO: Better triggering of filesList reload?
    return result;
  }

  const cuesInsights = useRef({} as {[key in QuestionCue]: {[key: string]: string[]}});
  const concernsInsights = useRef({} as {[key: string]: string[]});
  const [insightsUpdateCount, setInsightsUpdateCount] = useState(0);
  useEffect(()=>{
    const computeValues = async () => {
      // Compute QuestionCues Insights
      questionCues.forEach(cue => {
        filteredListings.forEach(file => {
          addToQueue(async ()=>{
            // TODO: Construct the question properly.
            const question = `Did the advisor use the following phrase exactly, or nearly exactly? "${cue}"`;
            const evidence = await getReportData(file, question);
            // await sleep(1000); // Add a short delay to avoid running over openai usage limits.
            if (evidence) {
              cuesInsights.current[cue] = cuesInsights.current[cue] ?? {};
              cuesInsights.current[cue][file.fullPath] = evidence;
            }

            setInsightsUpdateCount(count => count + 1);
          }, "process insights")
        })
      });

      //Process Main Concerns Question.
      const mainConcernsQuestion = "Did the advisee raise any concerns during the discussion?";
      filteredListings.forEach(file => {
        addToQueue(async ()=>{
          const evidence = await getReportData(file, mainConcernsQuestion);
          if (evidence) {
            concernsInsights.current[file.fullPath] = evidence;
          }

          setInsightsUpdateCount(count => count + 1);
        }, "process insights")
      });
      
      // Aggregate concerns across listings in a separate useEffect.

      // TODO: Compute average talk time per recording

      // TODO: Add support for custom queries.
    }
    computeValues();
  }, [filteredListings, addToQueue]); // For some reason, getting called twice, but the caching should take care of that.

  // A helper function to identify recurring themes in concerns raised.
  const summarizeConcerns = async (listOfConcernsLists: string[][]) => {
    const functionCallName = "store_digest";
    const resultPropName = 'recurring_concerns';
    const properties: { [key: string]: any } = {};
    properties[resultPropName] = {
      type: 'array',
      items: {
        type: 'string',
      },
      description: `A list of the most severe recurring themes in the concerns raised, ordered from most prevalent to least prevalent.`,
    };
    const response = await proxyChatCall({
      model: "gpt-4", // Results are better enough that this is worth it.
      // model: "gpt-4-turbo",
      temperature: 0,
      seed: 0,
      messages: [
        {
          role: "system",
          content: `You are a note-taking assistant for a financial advisor examining records from several recent client meetings. The user message includes lists of concerns raised in each of several client meetings. Please summarize the main concerns across all of the meetings and return the results as a topical list.`,
        },
        {
          role: "user",
          content: JSON.stringify(listOfConcernsLists),
        },
      ],
      tools: [
        {
          type: 'function',
          function: {
            name: functionCallName,
            description:
              "Return the summary of the main concerns that were raised.",
            parameters: {
              type: 'object',
              properties: properties,
              required: Object.keys(properties), // All properties are required.
            },
          },
        },
      ],
      tool_choice: { type: 'function', function: { name: functionCallName } },
    });
    const result = JSON.parse(
      (response.data as any)?.choices?.[0]?.message?.tool_calls?.[0]?.function.arguments
    )?.[resultPropName];

    return result;
  }

  // When the concerns insights are all updated, set the summary of the main issues.
  const [concernsSummary, setConcernsSummary] = useState(null as string[] | null);
  useEffect(()=>{
    setConcernsSummary(null); // Reset on trigger.
    // Check if all of the concerns summaries have been generated
    const concernsList = filteredListings.map((file)=>concernsInsights.current[file.fullPath]);
    if (concernsList.some(element => element === undefined)) {
      return;
    }

    // Moving forward with list of all concerns raised. Ask ChatGPT to summarize.
    // addToQueue(async ()=>{
    //   const result = await summarizeConcerns(concernsList);
    //   setConcernsSummary(result);
    // }, "process insights")
    
    queueConcernSummaries(async ()=>{
      const result = await summarizeConcerns(concernsList);
      setConcernsSummary(result);
    });
  }, [filteredListings, insightsUpdateCount, addToQueue]); // TODO: Still seems to retrigger; maybe because of uncached values.

  // Compute average talk time:
  // Do it synchronously; use useMemo if there are performance issues.
  const talkTimes: number[] = [];
  filteredListings.forEach((file)=>{
    const speakerUtteranceCounts = {} as {[key: string]: number};
    const utterances = JSON.parse(file.customMetadata?.labeledUtterances || "[]");
    utterances.forEach((utterance: any)=>{
      if (!("speaker" in utterance)) {
        return; // Skip what follows.
      }
      const speaker = utterance["speaker"]
      speakerUtteranceCounts[speaker] = (speakerUtteranceCounts[speaker] ?? 0) + utterance.text.split(" ").length;
    })


    const maxSpeakerCount = Math.max(...Object.values(speakerUtteranceCounts), 0);
    const sumSpeakerCounts = Object.values(speakerUtteranceCounts).reduce((acc, current) => acc + current, 0);

    talkTimes.push(1 - (maxSpeakerCount / Math.max(sumSpeakerCounts, 1)));
  });

  const averageTalkTime = talkTimes.reduce((acc, current) => acc + current, 0) / Math.max(talkTimes.length, 1);

  return <>
    <IonButton color="primary" routerLink="/smart-notes/record" style={{maxWidth: "50em", "margin": "auto"}}>+ Record New Meeting</IonButton>
    <IonGrid>
    <IonRow>
      <IonCol size="6" style={columnStyles}>

        <IonSearchbar
          value={searchText}
          onIonInput={e => setSearchText(e.detail.value!)}
        ></IonSearchbar>
        
        <IonItem>
          <IonLabel>Filter by start date{startDate && `: ${startDate}`}</IonLabel>
          <IonToggle
            checked={pickingStartDate}
            onIonChange={e => setPickingStartDate(e.detail.checked)}
          />
        </IonItem>
        {pickingStartDate && <>
          {/* {startDate && <IonButton color="danger" onClick={()=>{
            setStartDate(null); 
            setPickingStartDate(false);}}>Reset</IonButton>} */}
          <IonDatetime
            presentation="date"
            // placeholder="Select Start Date"
            value={startDate?.toISOString()}
            onIonChange={e => setStartDate(new Date(e.detail.value! as string))}
            style={{"margin": "auto"}}
          />
        </>}

        <IonItem>
          <IonLabel>Filter by end date{endDate && `: ${endDate}`}</IonLabel>
          <IonToggle
            checked={pickingEndDate}
            onIonChange={e => setPickingEndDate(e.detail.checked)}
          />
        </IonItem>
        {pickingEndDate && <>
          {/* {endDate && <IonButton color="danger" onClick={()=>{setEndDate(null); setPickingEndDate(false);}}>Reset</IonButton>} */}
          <IonDatetime
            presentation="date"
            // placeholder="Select Start Date"
            value={endDate?.toISOString()}
            onIonChange={e => setEndDate(new Date(e.detail.value! as string))}
            style={{"margin": "auto"}}
          />
        </>}

        {loadingListings? <IonSpinner/>: <></>}

        {/* <IonDatetime
          displayFormat="MM DD YYYY"
          placeholder="Select End Date"
          value={endDate?.toISOString()}
          onIonChange={e => setEndDate(new Date(e.detail.value!))}
        /> */}
        {filteredListings.map((file, index)=>{
          const date = file.customMetadata?.date;
          const label = file.customMetadata?.label;

          const dateString = date? (new Date(Number.parseInt(date))).toLocaleString() : "No Timestamp";

          return <IonCard key={index} routerLink={`/smart-notes/manage/${date}`}>
            <IonCardHeader><IonCardTitle>{dateString}</IonCardTitle></IonCardHeader>
            <IonCardContent>
              <p>Meeting with: {label}</p>
              {/* <p>Full Path: {file.fullPath}</p> */}
              {metadataComplete(file)? <></> : <IonText color={"danger"}><p>This meeting summary is incomplete.</p></IonText>}
              {file.customMetadata?.speakers? <></> : <IonText color={"warning"}><p style={{fontSize: ".8em"}}>Label speakers in this transcript for more accurate insights.</p></IonText>}
            </IonCardContent>
          </IonCard>
        })}
      </IonCol>
      <IonCol size="6" style={columnStyles}>
        <h2>Insights:</h2>
        <br/>
        <h3>Average Time Clients Spoke: {Math.round(averageTalkTime * 100)}%</h3>
        <p>(Aim for 50%)</p>
        <br/>
        <h3>Main Concerns:</h3>
        {concernsSummary===null? <p>Processing</p>:
          concernsSummary.length <= 0? <p>None</p>:
            <div style={{width: "fit-content", margin: "auto", textAlign: "left"}}>
              <ul>
                {concernsSummary.map((item, index)=><li key={index}>{item}</li>)}
              </ul>
            </div>
        }
        <br/>
        <h3>Question Cues Usage:</h3>
        {questionCues.map((cue, index) => {
          const evidence = filteredListings.map(file => cuesInsights.current?.[cue]?.[file.fullPath]);
          const unknownCount = evidence.filter(value => value === undefined).length;
          const yesCount = evidence.filter(value => value && value.length > 0).length;
          const noCount = evidence.length - yesCount - unknownCount;
          return <div key={index}>
            <h4>{cue}{unknownCount > 0? " | Processing" : ` | Used in ${yesCount} / ${yesCount + noCount} Meetings`}</h4>
            <ThreeBarChart greenNumber={yesCount} blueNumber={unknownCount} redNumber={noCount}/>
          </div>
        })}
      </IonCol>
    </IonRow>
  </IonGrid>
  </>
}