import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonText } from "@ionic/react";
import { RecommendationType } from "../../services/parameters/parameters-schema";
import { Link } from "react-router-dom";

export const InsightCard: React.FC<{recommendation: RecommendationType&{title:string}}> = ({recommendation})=>{
    const ionicColor = ({
        "green": "success",
        "yellow": "warning",
        "red": "danger"
    } as const)[recommendation.lightColor]

    return <IonCard>
        <IonCardHeader><IonCardTitle>{recommendation.title}</IonCardTitle></IonCardHeader>
        <IonCardContent>
            <p><strong>Status:</strong> <IonText color={ionicColor}>{recommendation.lightColor}</IonText></p>
            {recommendation.description.length > 0 && <p><strong>Description:</strong>: {recommendation.description}</p>}
            {recommendation.tags.length > 0 && <p><Link to={`/marketing-copy/${recommendation.tags.join(", ")}`}>Access custom communication materials</Link></p>}
        </IonCardContent>
    </IonCard>
}