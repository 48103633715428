import { IonButton, IonCheckbox, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow, IonSpinner, IonText } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";

import { functions } from "../App";
import { storage } from "../App";
import { httpsCallable } from "firebase/functions";
import { useAuth } from "../services/AuthProvider";
import { UploadMetadata, ref, uploadBytes } from "firebase/storage";
import ClientMultiSelector from "./ClientMultiSelector";
import { questionCues } from "./RecordingsManager";
import { useRecordings } from "../services/RecordingsProvider";
import { useNavigate } from "react-router-dom";

const Recorder: React.FC = () => {
  const transcribeRealTime = httpsCallable(functions, "transcribeRealTime");
  
  const { currentUser } = useAuth();
  const uid = currentUser?.uid || "NO_USER_IDENTIFIED";

  const [permissionToRecord, setPermissionToRecord] = useState(false);

  const [recording, setRecording] = useState<boolean>(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [realTimeRecorder, setRealTimeRecorder] = useState<MediaRecorder | null>(null);
  const [transcript, setTranscript] = useState<string>('');  // For storing transcript
  const [clientNames, setClientNames] = useState<string>('');

  const startTime = useRef(new Date());
  const lastWordsTime = useRef(new Date());
  const maxRunTimeMilliseconds = 2.5 * 60 * 60 * 1000; // 2.5 hours.
  const maxNoWordsTimeMilliseconds = 10 * 60 * 1000; // 10 minutes.

  const [fullAudio, setFullAudio] = useState<Blob | null>(null);
  const [saving, setSaving] = useState(false);
  const {refreshData} = useRecordings();

  const navigate = useNavigate();

  const realTimeIntervalID = useRef<NodeJS.Timeout | null>(null);
  const startRecording = (): void => {
      setRecording(true);
      //mediaRecorder && mediaRecorder.start(2 * 1000); // Timeslice in ms
      // TODO: Accumulate and append to transcription only timestamped new parts.
      mediaRecorder && mediaRecorder.start(); // Testing without timeslice.

      realTimeRecorder && realTimeRecorder.start();
      const intervalID = setInterval(()=>{
          realTimeRecorder?.stop();
          const currentTime = new Date();
          if (currentTime.getTime() - startTime.current.getTime() > maxRunTimeMilliseconds) {
            stopRecording();
          } else if (currentTime.getTime() - lastWordsTime.current.getTime() > maxNoWordsTimeMilliseconds) {
            stopRecording();
          } else {
            realTimeRecorder?.start();
          }
      }, 3 * 1000);
      realTimeIntervalID.current = intervalID;

      lastWordsTime.current = new Date();
      startTime.current = new Date();
      setTranscript("");
  };

  const stopRecording = (): void => {
      setRecording(false);
      mediaRecorder && mediaRecorder.stop();
      realTimeIntervalID.current && clearInterval(realTimeIntervalID.current);
      realTimeRecorder?.stop();
      realTimeIntervalID.current = null;
  };

  const replaceFirstAndLastWords = (text:string) => {
      const words = text.split(' ');
      if (words.length > 0) {
          words[0] = "...";
          words[words.length - 1] = "...";
      }
      return words.join(' ')
  }

  const handleRealTimeData = async (e: BlobEvent): Promise<void> => {
      // Convert blob to base64 to send via callable function
      const reader = new FileReader();
      reader.readAsDataURL(e.data);
      // const reader = new FileReader();
      // reader.readAsDataURL(newAudioBlob);
      reader.onloadend = async () => {
          const base64data = reader.result;
          const data = await transcribeRealTime({ audio: base64data }) as any;
          const transcript = data.data.channels[0].alternatives[0].transcript;
          const tweakedTranscript = replaceFirstAndLastWords(transcript);
          setTranscript(tweakedTranscript);
          // setTranscript(oldTranscript => `${oldTranscript}\n${data.data.results.channels[0].alternatives[0].transcript}`);
          const currentTime = new Date();
          if (transcript.trim().split(" ").length > 1) {
            lastWordsTime.current = currentTime;
            console.log(transcript.trim().split(" "));
          } 
      };
  };

  const handleFullData = async (e: BlobEvent): Promise<void> => {
      setFullAudio(e.data);
  };

  const saveData = async ()=> {
    if(!fullAudio) {
      return;
    }

    setSaving(true);
    // Upload audio to Firebase Storage
    const date = Date.now().toString();
    const path = `user/${currentUser?.uid}/audio/${date}.wav`;
    const storageRef = ref(storage, path);
    const metadata: UploadMetadata = {
        customMetadata: {
            date: date,
            label: clientNames,
        }
    }

    uploadBytes(storageRef, fullAudio, metadata).then((snapshot) => {
      // setSaving(false);
      navigate(`/smart-notes/manage/${date}`);
    }).catch((reason)=>{
      console.log(reason);
      // setSaving(false);
    }).finally(()=>{
      setSaving(false);
      refreshData();
    });


    // Call Firebase Function to transcribe audio
    // const transcribeAudio = functions.httpsCallable('transcribeAudio');
    // const data = await transcribeAudio({ path: audioRef.fullPath });
    // // Update transcript
    // setTranscript(oldTranscript => `${oldTranscript}\n${data.data.results.channels[0].alternatives[0].transcript}`);

  }

  useEffect(() => {
      navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then((stream) => {
          const newMediaRecorder = new MediaRecorder(stream);
          newMediaRecorder.ondataavailable = handleFullData;
          setMediaRecorder(newMediaRecorder);

          const newRealTimeRecorder = new MediaRecorder(stream);
          newRealTimeRecorder.ondataavailable = handleRealTimeData; // TODO: Differentiate.
          setRealTimeRecorder(newRealTimeRecorder);
      });
  }, []);

  return (
    <div>
      <p><IonText color="medium">Recording will stop automatically after 2.5 hours or 10 minutes of silence.</IonText></p>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="stacked">Client Name(s)</IonLabel>
              <ClientMultiSelector 
                onSelectionChange={(selection)=>{setClientNames(selection.join(", "))}}
              />
              {/* <IonInput onInput={(event)=>{
                  setClientNames(event.currentTarget.value + "");
              }}></IonInput> */}
            </IonItem>
            <IonItem>
              <IonLabel>Client has given permission to record.</IonLabel>
              <IonCheckbox slot="start" onIonChange={e => setPermissionToRecord(e.detail.checked)} />
            </IonItem>
          </IonCol>
          <IonCol>
            <h2>Remember to use these "Question Cues":</h2>
            <ul>
              {questionCues.map((cue, index)=>{
                return <li key={index} style={{"textAlign": "left"}}>{cue}</li>
              })}
            </ul>
          </IonCol>
        </IonRow>
      </IonGrid>

      
      {permissionToRecord? <>
        {!fullAudio?
        <IonButton onClick={startRecording} disabled={recording}>
            Start Recording
        </IonButton>
        :
        <IonButton color="danger" onClick={()=>{setFullAudio(null)}}>Delete Recording</IonButton>
        }
        
        <IonButton onClick={stopRecording} disabled={!recording}>
            Stop Recording
        </IonButton>
        <p>{recording? transcript: (fullAudio? "Recording complete. Add client names to save.": "Start recording any time.")}</p>
        <br/>

        {fullAudio? <IonButton color="success" onClick={saveData} disabled={!clientNames || saving}>{saving?<><IonSpinner/>&nbsp;</>:<></>}Save Audio</IonButton> : <></>}
      </>: <></>}
    </div>
  );
};

export default Recorder;