import React, { useState, useEffect } from 'react';
import { IonCol, IonRow, IonGrid, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonLabel, IonSearchbar, IonButton } from '@ionic/react';
import { LoginGuard } from '../LoginGuard';
import InfoStub from '../InfoStub';
import MultiColumnContent, { TabContent } from './multi-column-content';
import SurveyForm from '../../components/kyc-editor/survey-form';
import { ClientRecord, useClients } from '../../services/client-records/clients-provider';
import ClientDeleteButton from '../../services/client-records/client-delete-button';
import FileUpload from '../../services/client-records/file-upload';
import { useRecordings } from '../../services/RecordingsProvider';
import { ReportSummaryCard } from './report-summary-card';
import { useNavigate, useParams } from 'react-router-dom';

const ClientDetails: React.FC = () => {
  const {clientID:paramClientID} = useParams<{ clientID:string }>();
  const clientID = paramClientID || "NO_CLIENT_ID_FOUND";
  const { myClients } = useClients();
  const matchingClient = myClients.find(client => client.id===clientID);

  if (!matchingClient) {
    return <LoginGuard>
    <InfoStub title={"Client Not Found"} backRouterLink='/clients'>
      <p>No matching client found. This page will update if data for such a client synchronizes, but it's most likely that the client no longer exists.</p>
    </InfoStub>
  </LoginGuard>
  }


  const tabContents: TabContent[] = [
    { title: "Client Profile", content: <ProfileContent client={matchingClient}/> },
    { title: "Reports", content: <ReportsContent clientId={clientID}/> },
    { title: "Meetings", content: <MeetingsContent clientName={matchingClient.fullName}/> },
  ];

  return (
    <LoginGuard>
      <InfoStub title={matchingClient.fullName} backRouterLink='/clients'>
        <MultiColumnContent tabs={tabContents} />
      </InfoStub>
    </LoginGuard>
  );
};

const ProfileContent: React.FC<{client: ClientRecord}> = ({client}) => {
  const navigate = useNavigate();

  return (<>
    <SurveyForm client={client}/>
    <ClientDeleteButton client={client} onDelete={()=>{navigate("/clients")}}/>
  </>);
};

// const dummyReports = [
//   { reportDate: new Date(), reportType: 'Albridge', status: 'processing' },
//   { reportDate: new Date(new Date().setDate(new Date().getDate() - 1)), reportType: 'Morningstar', status: 'analyzing' },
//   { reportDate: new Date(new Date().setDate(new Date().getDate() - 2)), reportType: 'Albridge', status: 'recommendations available' },
// ];

const ReportsContent: React.FC<{clientId: string}> = ({clientId}) => {
  // const sortedReports = [...dummyReports].sort((a, b) => b.reportDate.getTime() - a.reportDate.getTime());

  const { myReports, addReport } = useClients();
  const clientReports = myReports.get(clientId) || [];

  return (
    <>
      <IonButton onClick={()=>{addReport({clientId: clientId})}}>+ New Report</IonButton>
      {clientReports.map((report, index) => (
        <ReportSummaryCard report={report} key={index}/>
      ))}
    </>
  );
};

// const dummyMeetings = [
//   { meetingDate: new Date(new Date().setDate(new Date().getDate() - 7)), summary: "Discussed the client's investment strategy and reviewed portfolio performance." },
//   { meetingDate: new Date(new Date().setDate(new Date().getDate() - 14)), summary: "Reviewed the client's financial goals and updated risk tolerance assessment." },
//   { meetingDate: new Date(new Date().setDate(new Date().getDate() - 20)), summary: "Provided recommendations for rebalancing the portfolio based on market conditions." },
// ];

const MeetingsContent: React.FC<{clientName: string}> = ({clientName}) => {
  const { filesList } = useRecordings();

  const filteredRecordings = filesList.filter((file)=>JSON.stringify(file.customMetadata).toLowerCase().includes(clientName.toLowerCase()))

  return (
    <>
      <IonButton routerLink='/smart-notes/record'>+ Record New Meeting</IonButton>
      {filteredRecordings.map((meeting, index) => (
        <IonCard key={index} style={{ marginBottom: "1em", maxHeight: "15em", overflow:"auto" }} routerLink="/smart-notes">
          <IonCardHeader>
            {meeting.customMetadata?.date && 
              <IonCardTitle>{new Date(Number(meeting.customMetadata?.date)).toLocaleString()}</IonCardTitle>
            }
          </IonCardHeader>
          <IonCardContent>
            {meeting.customMetadata?.summary.split("\n").map((paragraph, index) => <p key={index}>{paragraph}</p>)}
          </IonCardContent>
        </IonCard>
      ))}
      <p>Not seeing a meeting? Remember to tag meetings with the participants.</p>
    </>
  );
};

export default ClientDetails;
